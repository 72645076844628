<template>
  <div>
    <main id="content"
          class="broker-content">
      <HeaderBrokerComponent />
      <section class="dashboard-section">
        <div class="container">
          <div class="row align-items-center position-relative justify-content-between">
            <div class="col-lg-4 col-md-4">
              <h3 class="m-0">
                Charges
              </h3>
            </div>
            <div class="col-auto ml-md-auto mt-lg-1 mt-md-0 mt-2">
              <button class="btn btn-outline-primary"
                      @click="openModalFilter">
                <i class="icon-equalizer" /> FILTER
              </button>
            </div>
            <div class="modal-filter d-none"
                 id="modalFilter">
              <form>
                <label>Date from:</label>
                <div class="form-group">
                  <datepicker
                    placeholder="Select Date"
                    v-model="checkIn"
                    :clear-button="true" />
                </div>
                <label>Date to:</label>
                <div class="form-group">
                  <datepicker
                    placeholder="Select Date"
                    v-model="checkOut"
                    :clear-button="true" />
                </div>
                <label>Unit name</label>
                <div class="form-group has-icon">
                  <i class="icon-search" />
                  <input
                    v-model="unitName"
                    type="text"
                    class="form-control"
                    placeholder="Name">
                </div>
                <button type="button"
                        @click="getCharges">
                  Apply Filters
                </button>
              </form>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="accordion-table">
                <ul class="head-accordion listings-accordion">
                  <li>PROPERTY</li>
                  <li>DESCRIPTION</li>
                  <li>AMOUNT</li>
                  <li>DATE</li>
                  <li>INVOICE</li>
                </ul>
                <vsa-list class="no-arrow listings-accordion">
                  <vsa-item v-for="(charge, index) in charges"
                            :key="index">
                    <vsa-heading>
                      <div class="item-information-accordion-li">
                        <div class="d-flex align-items-center">
                          <div class="thumb">
                            <img :src="charge.property_image.thumbnail">
                          </div>
                          <h6>
                            <a class="text-black text-decoration-none"
                               href="javascript:;">{{ charge.property_name }}<small>{{ charge.property_location }}</small></a>
                          </h6>
                        </div>
                      </div>
                      <div class="item-information-accordion-li">
                        {{ charge.description }}
                      </div>
                      <div class="item-information-accordion-li">
                        {{ charge.amount }}
                      </div>
                      <div class="item-information-accordion-li">
                        {{ charge.date }}
                      </div>
                      <div class="item-information-accordion-li">
                        <a v-if="charge.invoice"
                           :href="charge.invoice"
                           target="_blank">VIEW FILE</a>
                        <a v-if="!charge.invoice">N/A</a>
                      </div>
                      <div class="item-information-accordion-li">
                        <i class="icon-cheveron-down" />
                      </div>
                    </vsa-heading>
                  </vsa-item>
                </vsa-list>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <myFooter />
  </div>
</template>

<script>
import { VsaList, VsaItem, VsaHeading } from 'vue-simple-accordion';
import Datepicker from 'vuejs-datepicker';
import HeaderBrokerComponent from '../../components/broker/Header.vue';

export default {
  name: 'Charges',
  components: {
    HeaderBrokerComponent,
    VsaList,
    VsaItem,
    VsaHeading,
    Datepicker,
  },
  data() {
    return {
      charges: null,
      limit: 100,
      checkIn: null,
      checkOut: null,
      unitName: null,
    };
  },
  created() {
    this.getCharges();
  },
  methods: {
    openModalFilter() {
      const modalFilter = document.getElementById('modalFilter');
      modalFilter.classList.toggle('d-none');
    },
    getCharges() {
      this.charges = null;
      let queryString = `?limit=${this.limit}`;
      if (this.unitName) {
        queryString += `&unit_name=${this.unitName}`;
      }
      if (this.checkIn) {
        const dateFrom = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(this.checkIn);
        queryString += `&date_from=${dateFrom}`;
      }
      if (this.checkOut) {
        const dateTo = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(this.checkOut);
        queryString += `&date_to=${dateTo}`;
      }
      this.$axios.get(`/v1/brokers/charges/${queryString}`).then((response) => {
        this.charges = response.data;
      });
    },
  }
};
</script>

<style>
  .accordion-table h6 {
    max-width: 140px;
  }
</style>
